import React, { useRef, useState } from 'react';
import { Text, useBreakpoint } from 'basis';
import styled from '@emotion/styled';
import { Icon } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';

const AccordionWrapper = styled.div`
  width: 100%;
  position: relative;
  height: ${props => (props.isOpen ? 'auto' : '60px')}; // Adjust 48px to match your header height
  overflow: hidden;
`;

const AccordionHeader = styled.div`
  background-color: #f5f5f5;
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  color: #414141;
`;

const AccordionContent = styled.div`
  padding: 18px;
  background-color: white;
  border-bottom: 1px solid #e0e0e0;
`;

const DEFAULT_LABEL = 'Things to know before you start';

export const AccordionAtXS = ({ label = DEFAULT_LABEL, size = 'small', children }) => {
  const breakpoint = useBreakpoint();
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef(null);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  // default: 0-374px, xs: 375px-575px,
  if (breakpoint !== 'default' && breakpoint !== 'xs') {
    return children;
  }

  return (
    <AccordionWrapper isOpen={isOpen}>
      <AccordionHeader onClick={toggleAccordion}>
        <Text textStyle="subtitle2">
          <strong>{label}</strong>
        </Text>
        <Icon
          as={ChevronDownIcon}
          transform={isOpen ? 'rotate(180deg)' : 'rotate(0)'}
          transition="transform 0.3s ease"
          boxSize="24px"
        />
      </AccordionHeader>
      <AccordionContent ref={contentRef}>{children}</AccordionContent>
    </AccordionWrapper>
  );
};
