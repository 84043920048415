import React, { useState, useRef } from 'react';
import { Text, Stack, Container, Button } from 'basis';
import { LayoutOutcomePage } from 'components';
import { displayCreditLimit } from 'utils';
import { UPLOAD_DOCUMENTS } from 'store';
import { useErrorTracking, useDataLayerAndAnalyticsLinks } from 'hooks';
import { useTheme } from '@emotion/react';
import { DocUpload } from 'doc-upload';
import { CHANNEL_INSTORE } from '_config';
import { OutcomeReferred } from '../OutcomeReferred/OutcomeReferred';
import { generateUploadConfig } from '../../doc-upload/uploadConfig';
import { List } from '../Outcome.styles';

export function POADocUpload({ storeState }) {
  const methodsRef = useRef(null);
  const theme = useTheme();
  const {
    applyAndBuy: { channel },
    applicationRef,
  } = storeState;
  const creditLimit = displayCreditLimit(storeState);
  const [uploading, setUploading] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  const { handleErrorTracking } = useErrorTracking();
  const { trackEventSender } = useDataLayerAndAnalyticsLinks(storeState);
  const dmsChannel = channel === CHANNEL_INSTORE ? 'INSTORE' : 'ONLINE';
  const handleDocUploadEvent = evt => {
    switch (evt.type) {
      case 'ERROR':
        setUploading(false);
        handleErrorTracking(evt.error.message || JSON.stringify(evt.error));
        break;
      case 'COMPLETE':
        setUploading(false);
        setUploaded(true);
        break;
      case 'INIT':
        methodsRef.current = {
          startUpload: evt.data.startUpload,
        };
        break;
      default:
        break;
    }
  };
  const config = generateUploadConfig({
    applicationRef,
    channel: dmsChannel,
    documentSubType: 'OTHER',
    handleDocUploadEvent,
  });

  const handleSubmit = async () => {
    setUploading(true);
    const result = await methodsRef.current?.startUpload?.();
    if (result) {
      trackEventSender({
        location: 'outcome',
        label: UPLOAD_DOCUMENTS,
      });
    }
    setUploading(false);
  };

  if (uploaded) {
    return <OutcomeReferred storeState={storeState} />;
  }

  return (
    <LayoutOutcomePage>
      <Stack gap="8">
        <Text as="h1" textStyle="heading5" textStyle-md="heading4" color={theme.headingColor}>
          Congratulations!
          <br />
          {`You’re conditionally approved for $${creditLimit}`}
          <br />
          We just need to verify your address
        </Text>
        <Text testId="gotEverythingForNow">
          Your application number is <strong data-hj-suppress>{applicationRef}</strong>.
        </Text>
        <Container>
          <Text margin="0 0 4">
            To help complete your Gem Visa application you need to upload once of the following documents.
          </Text>
          <Text>
            <strong>
              We need just one document from the following list, containing your name and address, from the last 6
              months.
            </strong>
          </Text>
          <List>
            <li>Utility bill</li>
            <li>Rates notice</li>
            <li>Bank statement or credit card statement</li>
            <li>Loan statement</li>
            <li>Home and contents insurance policy (12 months for annual policy)</li>
            <li>Government issued document</li>
            <li>Rental tenancy agreement</li>
            <li>Property purchase and sale agreements</li>
            <li>Letter from educational institutions</li>
            <li>Kiwisaver statement</li>
          </List>
        </Container>
        <Text>Once you’ve uploaded your documents our team will review and be in touch within 2 days.</Text>
        <Container margin="2 0">
          <DocUpload {...config} handleDocUploadEvent={handleDocUploadEvent} />
        </Container>
        <Container margin="4 0">
          <Button type="button" onClick={handleSubmit} disabled={uploading}>
            Submit Application
          </Button>
        </Container>
      </Stack>
    </LayoutOutcomePage>
  );
}
