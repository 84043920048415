import React from 'react';
import { Container, Flex, Grid, Text } from 'basis';
import { LayoutContent, MoneyEnvelopeIcon, StyledUL } from 'components';
import { DocUpload } from 'doc-upload';
import { byCountry } from 'utils';
import { generateUploadConfig } from './uploadConfig';
import { content } from './content';

export const UploadPayslips = ({ applicationRef, handleDocUploadEvent, channel }) => {
  const config = generateUploadConfig({
    applicationRef,
    channel,
    documentSubType: 'PAYSLIP',
    handleDocUploadEvent,
  });
  const contentByCountry = byCountry(content);
  return (
    <LayoutContent hasBreakpointWidth={false}>
      <Flex padding="0 0 6 0">
        <>
          <MoneyEnvelopeIcon />
          <Text margin="0 0 0 6">For a quick response, please upload a clear legible copy of your payslip(s).</Text>
        </>
      </Flex>
      <Container padding="0" margin="0">
        <Grid preset="page" rowsGap={6}>
          <Grid.Item colSpan="all">
            <Text>
              <strong>{contentByCountry.payslips.title}</strong>
            </Text>
          </Grid.Item>
          <Grid.Item colSpan="all">
            <StyledUL>
              {contentByCountry.payslips.items.map((item, index) => (
                <li key={`payslips-item-${index}`}>{item}</li>
              ))}
            </StyledUL>
          </Grid.Item>
          <Grid.Item colSpan="all">{contentByCountry.payslips.acceptableDocs}</Grid.Item>
          <Grid.Item colSpan="all">
            <DocUpload {...config} />
          </Grid.Item>
        </Grid>
      </Container>
    </LayoutContent>
  );
};
