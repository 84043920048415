import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { LayoutPage, Errors } from 'components';
import { useWaitAndRedirect, useEnv, useCountdownTimer } from 'hooks';
import { Container, Text, LoadingIcon, Flex, Stack } from 'basis';
import { useTheme } from '@emotion/react';
import { isFeatureOn } from 'featureToggles';
import { POITYPE_DOC_UPLOAD, POITYPE_PAYSLIP_UPLOAD, useStoreValue } from 'store';
import { PROCESSING_TIMEOUT } from 'store/constants';
import { byCountry } from 'utils';
import { isAU } from '../../utils/byConfig';

let isPoiDocUploadAU = false;
const useClearableCountdown = (updateStore, hasError, storeState) => {
  const history = useHistory();
  const getEnv = useEnv();

  const redirectToTimeout = () => {
    updateStore({
      activeStep: '/outcome-timeout',
      applicationStatusReason: PROCESSING_TIMEOUT,
    });
    history.push('/outcome-timeout');
  };

  isPoiDocUploadAU =
    isAU() &&
    (storeState.incomeVerificationType === POITYPE_DOC_UPLOAD ||
      storeState.incomeVerificationType === POITYPE_PAYSLIP_UPLOAD);
  const countdownTimer = isPoiDocUploadAU
    ? process.env.REACT_APP_AU_DOC_UPLOAD_APPLICATION_PROCESS_TIME_IN_SEC
    : getEnv(
        byCountry({
          NZ: 'REACT_APP_APPLICATION_PROCESS_TIME_IN_SEC',
          AU: 'REACT_APP_AU_APPLICATION_PROCESS_TIME_IN_SEC',
        }),
      );
  const countdown = useCountdownTimer(countdownTimer, redirectToTimeout);
  const clearCountdown = countdown[2];

  useEffect(() => {
    hasError && clearCountdown && clearCountdown();
  }, [clearCountdown, hasError]);
};
export const ProcessingPendingPOI = () => {
  const theme = useTheme();
  const dmsEnabled = isFeatureOn('nzDmsUpload') || isFeatureOn('auDmsUpload');
  const [storeState, updateStore] = useStoreValue();
  const { applicationId, applicationRef, applicationErrors } = storeState;

  const { retry, loading } = useWaitAndRedirect({
    applicationId,
    testId: 'longPollingWhileProcessingPendingPOI',
  });

  useClearableCountdown(updateStore, !!applicationErrors, storeState);

  if (applicationErrors) {
    return (
      <LayoutPage>
        <Errors applicationRef={applicationRef} retry={retry} retrying={loading} />
      </LayoutPage>
    );
  }

  if (dmsEnabled && isPoiDocUploadAU) {
    return (
      <LayoutPage>
        <Container testId="processingPendingPOI" height="400" padding="0" hasBreakpointWidth>
          <Flex height="100%" placeItems="center">
            <Stack gap="2">
              <Container textAlign="center" width="288px">
                <LoadingIcon size="large" color={theme.spinnerColor} />
                <Text as="h3" textStyle="heading5" color="black" align="center" margin="8 0 6 0">
                  Processing your documents
                </Text>
                <Text as="p" textStyle="body1" align="center">
                  Please hold tight.
                </Text>
              </Container>
            </Stack>
          </Flex>
        </Container>
      </LayoutPage>
    );
  }

  return (
    <LayoutPage>
      <Container testId="processingPendingPOI" height="400" padding="0" hasBreakpointWidth>
        <Flex height="100%" placeItems="center">
          {isAU() && (
            <Stack gap="2">
              <Container textAlign="center" width="288px">
                <LoadingIcon size="large" color={theme.spinnerColor} />
                <Text as="h6" textStyle="heading6" color="black" align="center" margin="8 0 2 0">
                  Verifying your income
                </Text>
                {!isPoiDocUploadAU && (
                  <>
                    <Text as="p" textStyle="body1" align="center">
                      This may take up to 2 minutes.
                    </Text>
                    <Text as="p" textStyle="body1" align="center">
                      Please hold tight.
                    </Text>
                  </>
                )}
                {isPoiDocUploadAU && (
                  <>
                    <Text as="p" textStyle="body1" align="center">
                      Please upload your income documents in the other browser tab.
                    </Text>
                    <Text as="p" textStyle="body1" align="center">
                      Do not close or refresh this tab.
                    </Text>
                    <Text as="p" textStyle="body1" align="center">
                      Once they've uploaded, return to this tab to continue your application.
                    </Text>
                    <Text as="p" textStyle="body1" align="center">
                      Processing may take a couple minutes.
                    </Text>
                  </>
                )}
              </Container>
            </Stack>
          )}
          {!isAU() && (
            <Stack gap="8">
              <Text textStyle="heading4" color={theme.headingColor} align="center">
                Please wait while we process your information.
              </Text>
              <Container textAlign="center">
                <LoadingIcon />
              </Container>
              <Text as="p" textStyle="subtitle2" align="center">
                This typically takes less than 2 minutes.
              </Text>
            </Stack>
          )}
        </Flex>
      </Container>
    </LayoutPage>
  );
};
