import { getAuthHeader, isAU } from 'utils';

export const generateUploadConfig = ({ applicationRef, channel, documentSubType, handleDocUploadEvent, POA }) => {
  const setAuthorizationHeaderValue = () => {
    const authHeader = getAuthHeader();
    return authHeader;
  };

  return {
    environment: process.env.REACT_APP_ENV === 'prod' ? 'production' : 'test',
    region: isAU() ? 'AU' : 'NZ',
    referenceType: 'APPLICATION_ID',
    reference: applicationRef,
    channel,
    clientId: isAU() ? 'ORIGINATIONS_AU' : 'ORIGINATIONS_NZ',
    businessUnit: 'LPAY',
    function: 'NEW_APPLICATION',
    documentType: POA ? 'PROOF_OF_ADDRESS' : 'PROOF_OF_INCOME',
    documentSubType,
    setAuthorizationHeaderValue,
    onEvent: handleDocUploadEvent,
  };
};
