import React from 'react';

const defaultColor = '#006aff';
const defaultSize = '32';

export const LatitudeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      focusable="false"
      role="img"
      aria-label="Latitude Icon"
      data-testid="latitude-icon"
    >
      <g clipPath="url(#clip0_3958_40379)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.9 6.60012C20.7403 6.60012 19.8001 7.54039 19.8001 8.70009C19.8001 9.8598 20.7403 10.8001 21.9 10.8001C23.0597 10.8001 24 9.8598 24 8.70009C24 7.54039 23.0597 6.60012 21.9 6.60012ZM21.9 4.19963C23.0597 4.19963 24 3.25967 24 2.09997C24 0.940266 23.0597 0 21.9 0C20.7403 0 19.8001 0.940266 19.8001 2.09997C19.8001 3.25967 20.7403 4.19963 21.9 4.19963ZM21.9 13.2006C20.7403 13.2006 19.8001 14.1405 19.8001 15.3005C19.8001 16.4602 20.7403 17.4002 21.9 17.4002C23.0597 17.4002 24 16.4602 24 15.3005C24 14.1405 23.0597 13.2006 21.9 13.2006ZM15.2999 4.19963C16.4596 4.19963 17.3996 3.25967 17.3996 2.09997C17.3996 0.940266 16.4596 0 15.2999 0C14.1402 0 13.2002 0.940266 13.2002 2.09997C13.2002 3.25967 14.1402 4.19963 15.2999 4.19963ZM8.69978 4.19963C9.85949 4.19963 10.7998 3.25967 10.7998 2.09997C10.7998 0.940266 9.85949 0 8.69978 0C7.54008 0 6.5995 0.940266 6.5995 2.09997C6.5995 3.25967 7.54008 4.19963 8.69978 4.19963ZM4.19994 19.8004H24V24H0V0H4.19994V19.8004Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_3958_40379">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const FaceIdIcon = ({ size = defaultSize, color = defaultColor }) => {
  return (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} focusable="false" role="img">
      <path
        d="M7 20a1 1 0 011 1v3h3a1 1 0 110 2H7a1 1 0 01-1-1v-4a1 1 0 011-1zm18 0a1 1 0 011 1v4a1 1 0 01-1 1h-4a1 1 0 110-2h3v-3a1 1 0 011-1zm-4-.189v.004c0 .37-.198.721-.529.887A9.955 9.955 0 0116 21.754a9.955 9.955 0 01-4.471-1.052.987.987 0 01-.529-.887v-.004a.996.996 0 011.449-.886 7.967 7.967 0 003.551.83 7.967 7.967 0 003.551-.83.996.996 0 011.449.886zM16 13a1 1 0 011 1v3a1 1 0 01-1 1h-1a1 1 0 110-2v-2a1 1 0 011-1zm-4-2a1 1 0 011 1v1a1 1 0 01-2 0v-1a1 1 0 011-1zm8 0a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zm-9-5a1 1 0 010 2H8v3a1 1 0 01-2 0V7a1 1 0 011-1h4zm14 0a1 1 0 011 1v4a1 1 0 11-2 0V8h-3a1 1 0 110-2h4z"
        fill={color}
      />
    </svg>
  );
};

export const MailIcon = ({ size = defaultSize, color = defaultColor }) => {
  return (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} focusable="false" role="img">
      <path
        d="M26 8a1 1 0 011 1v14a1 1 0 01-1 1H6a1 1 0 01-1-1V9a1 1 0 011-1h20zm-1 3.079l-9 7.202-9-7.201V22h18V11.079zM23.149 10H8.85L16 15.72 23.149 10z"
        fill={color}
      />
    </svg>
  );
};

export const TickIcon = ({ size = defaultSize, color = defaultColor }) => {
  return (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} focusable="false" role="img">
      <path
        d="M13.51 20.47l9.737-11.129a1 1 0 011.506 1.318l-10.5 12a1 1 0 01-1.514-.01l-4.5-5.28A1 1 0 019.76 16.07l3.749 4.399z"
        fill={color}
      />
    </svg>
  );
};

export const SecureIcon = ({ size = 24, color = 'white' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox={`0 0 ${size - 1} ${size}`} fill="none">
      <g clipPath="url(#clip0_13377_581)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.49048 9.96875V8.77691C9.49252 8.11209 9.75779 7.47515 10.2282 7.00542C10.6987 6.53569 11.3361 6.27141 12.0009 6.27039L12.0032 6.27039C12.6673 6.27243 13.3037 6.53716 13.7733 7.00678C14.2429 7.47641 14.5076 8.11277 14.5097 8.77691L14.5097 8.77844L14.5097 9.96875H14.92C15.516 9.96875 16 10.4528 16 11.0487V15.8898C16 16.4848 15.516 16.9688 14.92 16.9688H9.079C8.484 16.9688 8 16.4848 8 15.8898V11.0487C8 10.4528 8.484 9.96875 9.079 9.96875H9.49048ZM13.5097 9.96875V8.77926C13.5083 8.37953 13.3489 7.99656 13.0662 7.71389C12.7836 7.43132 12.4008 7.27192 12.0012 7.27039C11.6013 7.27131 11.2179 7.43044 10.9348 7.71307C10.6516 7.99581 10.4919 8.37913 10.4905 8.77927V9.96875H13.5097Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.2674 0.325361C11.457 0.117905 11.7252 -0.000195015 12.0063 2.41732e-07C12.2867 0.000196051 12.5542 0.118148 12.7434 0.325019L12.7441 0.32576L12.7462 0.328003L12.7657 0.348566C12.7848 0.368512 12.816 0.400645 12.8593 0.44344C12.9458 0.529064 13.0801 0.657147 13.2608 0.815537C13.6224 1.13261 14.1674 1.56925 14.8843 2.02946C16.3192 2.95054 18.4283 3.95729 21.127 4.30276C21.6261 4.36665 22 4.79147 22 5.29466V14.9451C22 14.9798 21.9977 15.0143 21.9948 15.0488C21.9931 15.0675 21.9908 15.0907 21.9874 15.1182C21.9808 15.1733 21.9703 15.2456 21.9539 15.3337C21.9212 15.51 21.8648 15.7492 21.768 16.0392C21.574 16.6205 21.2197 17.3997 20.5756 18.2796C19.291 20.0348 16.8946 22.1365 12.4459 23.8923C12.2007 24.0163 11.9056 24.0392 11.6296 23.9311C7.12937 22.1679 4.71295 20.0518 3.42174 18.2864C2.77819 17.4065 2.42445 16.6274 2.23095 16.0461C2.13442 15.7561 2.07826 15.5169 2.04567 15.3406C2.02938 15.2525 2.01899 15.1802 2.01238 15.1251C2.00908 15.0976 2.00672 15.0744 2.00505 15.0557L2.00301 15.031L2.00204 15.017C2.00097 14.9999 2 14.9756 2 14.9532V5.3C2 4.79683 2.37387 4.37202 2.87296 4.3081C5.57013 3.96267 7.6821 2.95466 9.12046 2.03197C9.83909 1.57097 10.3859 1.13352 10.7489 0.815798C10.9302 0.657082 11.0651 0.528714 11.1521 0.442868C11.1956 0.399961 11.227 0.367732 11.2462 0.34771L11.2658 0.327045L11.2674 0.325361ZM4 14.9013C4.00229 14.9181 4.00615 14.9436 4.01234 14.9771C4.02905 15.0674 4.06277 15.2168 4.12856 15.4144C4.25972 15.8084 4.52043 16.4007 5.03604 17.1057C6.03262 18.4682 8.02115 20.3044 11.9888 21.9209C15.9682 20.3037 17.9624 18.4637 18.9617 17.0984C19.4779 16.3932 19.7392 15.8004 19.8708 15.4061C19.9369 15.2082 19.9708 15.0587 19.9876 14.9682C19.9938 14.9349 19.9977 14.9095 20 14.8927V6.15189C17.358 5.67423 15.2693 4.65322 13.8039 3.71253C13.0309 3.21627 12.4281 2.74039 12.0049 2.374C11.5806 2.74093 10.9758 3.21793 10.2003 3.71537C8.7325 4.65698 6.64141 5.6792 4 6.15714V14.9013Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_13377_581">
          <rect width={size} height={size} fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};

export const HourglassIcon = () => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.027 13.143L15.684 17.973C14.684 17.695 13.414 17.528 12.028 17.528C10.641 17.528 9.37 17.695 8.37 17.974L12.027 13.143ZM16.027 4.556V6.63L14.681 8.407H9.373L8.027 6.63V4.556H16.027ZM19.027 20.5H18.027V18.09C18.027 17.872 17.956 17.661 17.824 17.487L14.07 12.528L17.824 7.569C17.956 7.395 18.027 7.184 18.027 6.966V4.556H19.027C19.594 4.556 20.054 4.095 20.054 3.528C20.054 2.96 19.594 2.5 19.027 2.5H5.027C4.46 2.5 4 2.96 4 3.528C4 4.095 4.46 4.556 5.027 4.556H6.027V6.966C6.027 7.184 6.098 7.396 6.23 7.569L9.985 12.528L6.23 17.487C6.098 17.661 6.027 17.872 6.027 18.09V20.5H5.027C4.46 20.5 4 20.96 4 21.528C4 22.095 4.46 22.556 5.027 22.556H19.027C19.594 22.556 20.054 22.095 20.054 21.528C20.054 20.96 19.594 20.5 19.027 20.5Z"
        fill="#414141"
      />
    </svg>
  );
};

export const ExternalLinkIcon = ({ size = 24, ...rest }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      role="img"
      {...rest}
    >
      <path
        d="M11 3.0001C11.552 3.0001 11.999 3.4471 11.999 4.0001C11.999 4.5511 11.552 5.0001 11 5.0001H5V19.0001H19V12.9861C19 12.4331 19.447 11.9861 19.999 11.9861C20.552 11.9861 20.999 12.4331 20.999 12.9861L21 19.0001C21 20.1041 20.104 21.0001 19 21.0001H5C3.895 21.0001 3 20.1041 3 19.0001V5.0001C3 3.8951 3.895 3.0001 5 3.0001H11ZM20.9988 2C21.1288 2 21.2598 2.026 21.3818 2.077C21.4938 2.124 21.5938 2.193 21.6808 2.275C21.6888 2.282 21.6988 2.285 21.7068 2.292L21.7228 2.316C21.8058 2.404 21.8748 2.504 21.9218 2.617C21.9728 2.739 21.9998 2.87 21.9998 3V9C21.9998 9.552 21.5518 10 20.9998 10C20.4468 10 19.9998 9.552 19.9998 9V5.414L15.7208 9.693C15.3298 10.084 14.6968 10.084 14.3068 9.693C13.9158 9.303 13.9158 8.669 14.3068 8.279L18.5848 4H14.9998C14.4468 4 13.9998 3.552 13.9998 3C13.9998 2.447 14.4468 2 14.9998 2H20.9988Z"
        fill="currentColor"
      />
    </svg>
  );
};

/**
 * @param {Object} props
 * @param {number} [props.width=35]
 * @param {number} [props.height=44]
 */
export function MoneyDocumentsIcon({ width = 35, height = 44 }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 35 44" fill="none">
      <path
        d="M7.85742 4.54297V8.1144V8.47154L29.8217 8.91797V39.7215H31.8753L34.1074 6.15011L7.85742 4.54297Z"
        fill="#DAD1FA"
      />
      <path
        d="M3.53337 34.618L0.714844 34.4217L3.02511 0.714844L29.2428 2.50915L29.0272 5.63571M7.59263 8.51127L7.88912 4.18722L34.1068 5.98153L31.7965 39.6922L29.8213 39.5574M7.64286 31.0957H25.7053M7.64286 34.4397H25.7053M7.64286 37.7866H25.7053M16.6979 13.7697V15.3079M18.7857 16.9467C18.7857 16.9467 18.7857 15.3076 16.7001 15.3076C14.6145 15.3076 14.5595 17.4176 14.5595 17.4176C14.5595 17.4176 14.5595 19.5889 16.6665 19.5889C18.7826 19.5889 18.7765 21.7631 18.7765 21.7631C18.7765 21.7631 18.7765 23.8701 16.6359 23.8701C14.4952 23.8701 14.59 22.2341 14.59 22.2341M16.6979 23.869V25.4072M3.53292 8.51339H29.8122V42.3011H3.53292V8.51339Z"
        stroke="#414141"
        strokeWidth="1.42857"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

/**
 * @param {Object} props
 * @param {number} [props.size=48]
 */
export function MoneyEnvelopeIcon({ size = 48 }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 48 48" fill="none">
      <path
        d="M7.8125 36.0391C6.125 34.1016 4.375 29.8516 2.6875 20.2266L1.625 16.9766L0.5 17.8516V28.1016L1 45.1018L2.375 46.4518H32.75C11.3125 43.3516 7.8125 36.0391 7.8125 36.0391Z"
        fill="#FFC9E4"
      />
      <path
        d="M4.36719 20.4795V11.7409C4.36719 11.4387 4.48722 11.1489 4.70089 10.9353C4.91455 10.7216 5.20434 10.6016 5.50651 10.6016H41.9649C42.267 10.6016 42.5568 10.7216 42.7705 10.9353C42.9842 11.1489 43.1042 11.4387 43.1042 11.7409V20.5763M4.37256 15.0273L1.07422 17.7503M37.8718 10.6011L28.1419 2.55744C26.9183 1.55053 25.3829 1 23.7983 1C22.2136 1 20.6782 1.55053 19.4546 2.55744L9.73047 10.6011M46.5234 17.7515L43.1055 14.9316M24.1338 15.0276V16.8686M26.6335 19.0051C26.6335 19.0051 26.6335 17.0432 24.1373 17.0432C21.6411 17.0432 21.5752 19.5687 21.5752 19.5687C21.5752 19.5687 21.5752 22.1674 24.097 22.1674C26.6298 22.1674 26.6225 24.7697 26.6225 24.7697C26.6225 24.7697 26.6225 27.2915 24.0604 27.2915C21.4984 27.2915 21.6118 25.3333 21.6118 25.3333M24.1338 27.6018V29.4429M19.4572 32.9391L1.07422 17.752V44.1729C1.07422 44.7772 1.31429 45.3568 1.74162 45.7841C2.16895 46.2115 2.74853 46.4515 3.35287 46.4515H44.2489C44.8532 46.4515 45.4328 46.2115 45.8601 45.7841C46.2875 45.3568 46.5275 44.7772 46.5275 44.1729V17.752L28.1446 32.9391C26.9209 33.946 25.3855 34.4966 23.8009 34.4966C22.2162 34.4966 20.6808 33.946 19.4572 32.9391Z"
        stroke="#414141"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
