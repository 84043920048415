/* eslint-disable no-script-url */
/* eslint-disable consistent-return */
import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import {
  useDataLayerAndAnalyticsLinks,
  useSubmitSavedApplication,
  useUpdatePOIType,
  useErrorTrackingAndUpdateStore,
} from 'hooks';
import { Container, Text } from 'basis';
import {
  useStoreValue,
  SHARE_BANK_STATEMENT,
  UPLOAD_PAYSLIPS,
  UPLOAD_DOCUMENTS,
  POITYPE_BANK_CONNECT,
  POITYPE_PAYSLIP_UPLOAD,
  POITYPE_DOC_UPLOAD,
  PROVIDE_PAYSLIPS,
  PROVIDE_DOCUMENTS,
  ONLINE_BANK_CONNECT,
  UPLOAD_YOUR_DOCUMENTS,
} from 'store';
import { LayoutPage, LayoutContent, InformationCard, Errors, SaveAndCompleteLaterButton } from 'components';
import ShareBankStatement from 'images/vi_share_your_bank_statement.svg';
import ShareFiConnectAU from 'images/vi_share_fi-connect_au.svg';
import DocumentUpload from 'images/vi_document_upload_au.svg';
import UploadDocuments from 'images/vi_upload_other_documents.svg';
import UploadPayslips from 'images/vi_upload_your_payslips.svg';
import { isEligibleForBankConnectAu } from '_config';
import { isEligibleForBankConnect } from '_config/_constants';
import { byCountry, isAU, isNZ } from 'utils/byConfig';
import styled from '@emotion/styled';
import { device } from 'media';
import { isFeatureOn } from 'featureToggles';
import {
  ShareBankStatementContent,
  UploadPayslipsContent,
  UploadDocumentsContent,
  OnlineBankConnectContent,
  DocumentUploadContentAU,
} from './VerifyIncomeCards.content';
import { ShareBankStatementModal } from './ShareBankStatement.modal';
import { UploadPayslipsModal } from './UploadPayslips.modal';
import { UploadDocumentsModal } from './UploadDocuments.modal';
import { BeforeUploadDocumentsModal } from './BeforeUploadDocuments.modal';
import { BeforeUploadPayslipsModal } from './BeforeUploadPayslips.modal';
import { OnlineBankConnectModal } from './OnlineBankConnect.modal';
import { PoiMethodsAU } from './PoiMethods';

const HR = styled.hr`
  margin: 40px 0;
  border: none;
  border-top: 2px solid #7f7f7f;
  color: #333;
  overflow: visible;
  text-align: center;
  height: 5px;

  :after {
    background: #7f7f7f;
    border-radius: 50%;
    letter-spacing: -0.35px;
    text-edge: cap;
    font-size: 16px;
    content: 'or';
    padding: 9px 11px;
    position: relative;
    top: -13px;
    color: var(--blacks-and-greys-black-03, #f8f8f8);
  }
`;
const ButtonContainer = styled.div`
  margin: 32px 0;
  @media ${device.mobileOrSmaller} {
    button {
      width: 100%;
    }
  }
`;

const VerifyIncome = () => {
  const [storeState, updateStore] = useStoreValue();
  const [openModal, setOpenModal] = useState('');
  const history = useHistory();

  const { trackEventSender } = useDataLayerAndAnalyticsLinks(storeState);
  const { handleErrorCallback } = useErrorTrackingAndUpdateStore();

  const BEFORE_UPLOAD_DOCUMENTS = 'BEFORE_UPLOAD_DOCUMENTS';
  const BEFORE_UPLOAD_PAYSLIPS = 'BEFORE_UPLOAD_PAYSLIPS';

  const handleOpenModal = useCallback(type => {
    setOpenModal(type);
  }, []);

  const handleShareBankStatement = () => {
    updateStore({
      activeStep: '/verify-ficonnect',
      poiStartDate: new Date().toISOString(),
      incomeVerificationType: POITYPE_BANK_CONNECT,
    });
    trackEventSender({
      category: 'application',
      action: 'application-navigation',
      location: 'verify-income',
      label: 'Share bank statement',
    });
    history.push('/verify-ficonnect');
  };

  const goToProcessingPage = () => {
    updateStore({
      applicationErrors: null,
      activeStep: '/processing',
    });

    history.push('/processing');
  };

  const goToReplayIncomePage = useCallback(() => {
    updateStore({
      applicationErrors: null,
      activeStep: '/confirm-income',
    });

    history.push('/confirm-income');
  }, [history, updateStore]);
  const goToDocumentUploadPage = useCallback(() => {
    updateStore({
      applicationErrors: null,
      activeStep: '/verify-document-upload',
    });

    history.push('/verify-document-upload');
  }, [history, updateStore]);

  const { submit, loading } = useSubmitSavedApplication({
    storeState,
    onSuccess: goToProcessingPage,
    onError: handleErrorCallback,
  });
  const { updatePOI } = useUpdatePOIType({
    storeState,
    onSuccess: goToDocumentUploadPage,
    onError: handleErrorCallback,
  });
  const handleSubmitOtherWays = useCallback(
    ({ label }) => {
      trackEventSender({
        category: 'application',
        action: byCountry({
          AU: 'application-submitted',
          NZ: 'application-navigation',
        }),
        location: 'verify-income',
        label,
      });

      const incomeVerificationType = label === UPLOAD_PAYSLIPS ? POITYPE_PAYSLIP_UPLOAD : POITYPE_DOC_UPLOAD;
      updateStore({ incomeVerificationType });
      if (isFeatureOn('incomeReplay')) {
        goToReplayIncomePage();
      } else if (isAU() || (isNZ() && isFeatureOn('nzDmsUpload'))) {
        goToDocumentUploadPage();
      } else {
        // when NZ with DMS feature off
        submit({ incomeVerificationType });
      }
    },
    [goToReplayIncomePage, submit, trackEventSender, updateStore, goToDocumentUploadPage],
  );

  const handleUploadDocuments = useCallback(() => handleSubmitOtherWays({ label: UPLOAD_DOCUMENTS }), [
    handleSubmitOtherWays,
  ]);

  const handleUploadYourDocuments = useCallback(() => handleSubmitOtherWays({ label: UPLOAD_YOUR_DOCUMENTS }), [
    handleSubmitOtherWays,
  ]);

  const handleUploadPayslips = useCallback(() => handleSubmitOtherWays({ label: UPLOAD_PAYSLIPS }), [
    handleSubmitOtherWays,
  ]);

  const openBeforeUploadPayslipsModal = useCallback(() => handleOpenModal(BEFORE_UPLOAD_PAYSLIPS), [handleOpenModal]);
  const openBeforeUploadDocumentsModal = useCallback(() => handleOpenModal(BEFORE_UPLOAD_DOCUMENTS), [handleOpenModal]);

  const closeAllModals = useCallback(() => setOpenModal(''), [setOpenModal]);

  const showBankConnectOption = byCountry({
    AU: isEligibleForBankConnectAu(storeState.acquisition.employmentDetails.employmentStatus),
    NZ: isEligibleForBankConnect(storeState.acquisition.employmentDetails.employmentStatus),
  });

  if (storeState.applicationErrors) {
    const retry = () => {
      updateStore({
        applicationErrors: null,
      });

      if (isFeatureOn('incomeReplay')) {
        goToReplayIncomePage();
      } else if (isAU()) {
        updatePOI(storeState.incomeVerificationType);
      } else {
        submit({ incomeVerificationType: storeState.incomeVerificationType });
      }
    };

    return (
      <LayoutPage hideBackButton>
        <Errors retry={retry} retrying={loading} />
      </LayoutPage>
    );
  }

  const VerifyIncomeOptionsNZ = () => {
    return (
      <Container>
        {showBankConnectOption && (
          <>
            <Container>
              <Text>
                <strong>The fastest way to get an outcome</strong>
              </Text>
            </Container>
            <InformationCard
              icon={ShareBankStatement}
              iconAlt={SHARE_BANK_STATEMENT}
              title="Share your bank statement"
              btnText="Share bank statement"
              onClick={handleShareBankStatement}
              testId="share_bank_statement"
              variant="primary"
            >
              <ShareBankStatementContent trackEventSender={trackEventSender} handleOpenModal={handleOpenModal} />
            </InformationCard>
            <Container padding="4 0 0 0">
              <Text>Other ways to prove your income</Text>
            </Container>
          </>
        )}

        <InformationCard
          icon={UploadPayslips}
          iconAlt={UPLOAD_PAYSLIPS}
          title="Upload your payslips"
          onClick={showBankConnectOption ? openBeforeUploadPayslipsModal : handleUploadPayslips}
          btnText={isFeatureOn('dynamicCreditLimit') ? PROVIDE_PAYSLIPS : UPLOAD_PAYSLIPS}
          loading={storeState.incomeVerificationType === POITYPE_PAYSLIP_UPLOAD && loading}
          testId="upload_payslips"
        >
          <UploadPayslipsContent trackEventSender={trackEventSender} handleOpenModal={handleOpenModal} />
        </InformationCard>

        <InformationCard
          icon={UploadDocuments}
          iconAlt={UPLOAD_DOCUMENTS}
          title="Upload other documents"
          onClick={showBankConnectOption ? openBeforeUploadDocumentsModal : handleUploadDocuments}
          btnText={isFeatureOn('dynamicCreditLimit') ? PROVIDE_DOCUMENTS : UPLOAD_DOCUMENTS}
          loading={storeState.incomeVerificationType === POITYPE_DOC_UPLOAD && loading}
          testId="upload_documents"
        >
          <UploadDocumentsContent trackEventSender={trackEventSender} handleOpenModal={handleOpenModal} />
        </InformationCard>
      </Container>
    );
  };

  const VerifyIncomeOptionsAU = () => {
    return isFeatureOn('auDmsUpload') ? (
      <PoiMethodsAU
        showBankConnectOption={showBankConnectOption}
        onClickBankConnect={handleShareBankStatement}
        onClickPayslipUpload={handleUploadPayslips}
        onClickDocUpload={handleUploadYourDocuments}
      />
    ) : (
      <Container padding="0" margin="6 0 0 0">
        <Container bg="grey.t03" padding="4" padding-md="10">
          {showBankConnectOption && (
            <>
              <InformationCard
                icon={ShareFiConnectAU}
                iconAlt={ONLINE_BANK_CONNECT}
                title={ONLINE_BANK_CONNECT}
                btnText="Verify online"
                onClick={handleShareBankStatement}
                testId="verify_online"
                variant="primary"
              >
                <OnlineBankConnectContent trackEventSender={trackEventSender} handleOpenModal={handleOpenModal} />
              </InformationCard>
              <HR />
            </>
          )}

          <InformationCard
            icon={DocumentUpload}
            iconAlt={UPLOAD_DOCUMENTS}
            title="Document Upload"
            onClick={handleUploadYourDocuments}
            btnText={UPLOAD_YOUR_DOCUMENTS}
            loading={storeState.incomeVerificationType === POITYPE_DOC_UPLOAD && loading}
            testId="upload_your_documents"
          >
            <DocumentUploadContentAU trackEventSender={trackEventSender} />
          </InformationCard>
        </Container>
      </Container>
    );
  };
  const VerifyIncomeOptions = () => {
    return isAU() ? <VerifyIncomeOptionsAU /> : <VerifyIncomeOptionsNZ />;
  };
  return (
    <LayoutPage>
      <Container margin="0 0 13 0">
        <LayoutContent rowsGap="0">
          <Container padding="0 0 6 0" padding-md="0 0 8 0">
            <Text>
              {byCountry({
                AU: "You're almost there! We just need to verify a few things before we finalise your application.",
                NZ: 'NZ legislation requires us to verify your income.',
              })}
            </Text>
            <Text />
          </Container>

          {isAU() && (
            <Container>
              <Text>
                {isFeatureOn('auDmsUpload') ? (
                  <>
                    Please select <strong>one</strong> of the following options to verify your income.
                  </>
                ) : (
                  <>
                    Please select <strong>one</strong> of the following options to verify your information.
                  </>
                )}
              </Text>
            </Container>
          )}
          <VerifyIncomeOptions />
          {isAU() && (
            <ButtonContainer>
              <SaveAndCompleteLaterButton />
            </ButtonContainer>
          )}
        </LayoutContent>
      </Container>

      <ShareBankStatementModal isOpen={openModal === SHARE_BANK_STATEMENT} onClose={closeAllModals} />
      <OnlineBankConnectModal isOpen={openModal === ONLINE_BANK_CONNECT} onClose={closeAllModals} />
      <UploadPayslipsModal isOpen={openModal === UPLOAD_PAYSLIPS} onClose={closeAllModals} />
      <UploadDocumentsModal isOpen={openModal === UPLOAD_DOCUMENTS} onClose={closeAllModals} />
      <BeforeUploadPayslipsModal
        isOpen={openModal === BEFORE_UPLOAD_PAYSLIPS}
        onClose={closeAllModals}
        onContinue={handleUploadPayslips}
        handleShareBankStatement={handleShareBankStatement}
      />
      <BeforeUploadDocumentsModal
        isOpen={openModal === BEFORE_UPLOAD_DOCUMENTS}
        onClose={closeAllModals}
        onContinue={handleUploadDocuments}
        handleShareBankStatement={handleShareBankStatement}
      />
    </LayoutPage>
  );
};

export { VerifyIncome };
