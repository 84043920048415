import React from 'react';
import {
  PROOF_OF_ADDRESS,
  ANTI_MONEY_LAUNDERING,
  PROOF_OF_INCOME,
  PROOF_OF_ADDRESS_AND_PROOF_OF_INCOME,
  ANTI_MONEY_LAUNDERING_AND_PROOF_OF_INCOME,
  POITYPE_PAYSLIP_UPLOAD,
  POITYPE_DOC_UPLOAD,
} from 'store';
import { isNZ } from 'utils';
import { isFeatureOn } from 'featureToggles';
import { ProofOfAddress } from './ProofOfAddress';
import { AntiMoneyLaundering } from './AntiMoneyLaundering';
import { ProofOfIncome } from './ProofOfIncome';
import { POADocUpload } from './POADocUpload';

import { OutcomeConditionallyUpload } from '../OutcomeConditionallyUpload';
import { OutcomeReferred } from '../OutcomeReferred/OutcomeReferred';

const isAntiMoneyLaunderingCase = reason => {
  return [ANTI_MONEY_LAUNDERING, ANTI_MONEY_LAUNDERING_AND_PROOF_OF_INCOME].includes(reason);
};

const isPayslipOrDocUpload = type => {
  return [POITYPE_PAYSLIP_UPLOAD, POITYPE_DOC_UPLOAD].includes(type);
};

const isProofOfAddressCase = reason => {
  return [PROOF_OF_ADDRESS, PROOF_OF_ADDRESS_AND_PROOF_OF_INCOME].includes(reason);
};

function OutcomeConditionallyApproved({ storeState }) {
  const { applicationStatusReason, incomeVerificationType } = storeState;
  const nzDmsUpload = isNZ() && isFeatureOn('nzDmsUpload');

  if (nzDmsUpload) {
    if (isAntiMoneyLaunderingCase(applicationStatusReason)) {
      return <AntiMoneyLaundering storeState={storeState} withPOI={false} />;
    }

    if (isProofOfAddressCase(applicationStatusReason)) {
      return <POADocUpload storeState={storeState} />;
    }

    if (applicationStatusReason === PROOF_OF_INCOME) {
      return <OutcomeReferred storeState={storeState} />;
    }
  }

  if (isPayslipOrDocUpload(incomeVerificationType)) {
    return <OutcomeConditionallyUpload storeState={storeState} />;
  }

  switch (applicationStatusReason) {
    case PROOF_OF_ADDRESS:
      return <ProofOfAddress storeState={storeState} />;
    case ANTI_MONEY_LAUNDERING:
      return <AntiMoneyLaundering storeState={storeState} />;
    case PROOF_OF_INCOME:
      return <ProofOfIncome storeState={storeState} />;
    case PROOF_OF_ADDRESS_AND_PROOF_OF_INCOME:
      return <ProofOfAddress storeState={storeState} withPOI={!nzDmsUpload} />;
    case ANTI_MONEY_LAUNDERING_AND_PROOF_OF_INCOME:
      return <AntiMoneyLaundering storeState={storeState} withPOI={!nzDmsUpload} />;
    default:
      return <OutcomeReferred storeState={storeState} />;
  }
}

export { OutcomeConditionallyApproved };
