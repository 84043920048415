/* eslint-disable no-script-url */
/* eslint-disable consistent-return */
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useEventTracking } from 'react-event-tracker';
import {
  useDataLayerAndAnalyticsLinks,
  useSubmitSavedApplication,
  useUpdatePOIType,
  useErrorTrackingAndUpdateStore,
  useErrorTracking,
  useEnv,
  useSteps,
} from 'hooks';
import { Accordion, Container, Grid, Message, Select, Text, Link, Button } from 'basis';
import { useStoreValue } from 'store';
import { LayoutPage, LayoutContent, Errors, StyledUL, Form, SaveAndCompleteLaterButton } from 'components';
import {
  CASUAL_SEASONAL_TEMPORARY,
  CHILD_SUPPORT,
  config,
  GOVERNMENT_BENEFITS,
  incomeEmploymentStatusOptionsAU,
  PERMANENT_FULL_PART_TIME_FIXED_TERM,
  RENTAL_INCOME,
  SELF_EMPLOYED_SUBCONTRACTOR,
  SELF_FUNDED_RETIREES,
  WORKERS_COMPENSATION,
  CHANNEL_INSTORE,
} from '_config';
import { getUploadUrl, submitHandler, byCountry, isAU, isNZ } from 'utils';
import { isFeatureOn } from 'featureToggles';
import { VerifyIncomeContent } from '../verify-income/verifyIncomeContent';
import { YellowMessage } from '../../components/message/YellowMessage';
import { UploadPayslips } from './UploadPayslips';
import { UploadOtherDocuments } from './UploadOtherDocuments';

export function VerifyDocumentUpload() {
  const [storeState, updateStore] = useStoreValue();
  const [uploading, setUploading] = useState(false);
  const history = useHistory();
  const { pathname, previousStep } = useSteps();

  const { trackEventSender } = useDataLayerAndAnalyticsLinks(storeState);
  const { handleErrorCallback } = useErrorTrackingAndUpdateStore();
  const { handleErrorTracking } = useErrorTracking();
  const { trackEvent } = useEventTracking();
  const {
    applicationRef,
    acquisition,
    incomeVerificationType,
    applyAndBuy: { channel },
  } = storeState;
  const { firstName, lastName } = acquisition.personalDetails;
  const { employmentStatus } = acquisition.employmentDetails;
  const auDmsUpload = isFeatureOn('auDmsUpload');
  const nzDmsUpload = isFeatureOn('nzDmsUpload');
  const showUploadOtherDocuments = incomeVerificationType === 'POITYPE_DOC_UPLOAD';
  const showUploadPayslips = incomeVerificationType === 'POITYPE_PAYSLIP_UPLOAD';
  const showDMSView =
    (isAU() && auDmsUpload && (showUploadPayslips || showUploadOtherDocuments)) ||
    (isNZ() && nzDmsUpload && (showUploadPayslips || showUploadOtherDocuments));
  const dmsChannel = channel === CHANNEL_INSTORE ? 'INSTORE' : 'ONLINE';

  const onClickBack = useCallback(() => {
    trackEvent({
      event: {
        category: 'application',
        action: 'application-navigation',
        location: pathname.slice(1),
        label: 'Back',
      },
    });
    if (previousStep) {
      history.push(previousStep);
    }
  }, [history, previousStep, trackEvent, pathname]);

  const getEnv = useEnv();
  const initialValues = useMemo(
    () => ({
      incomeEmploymentType: '',
    }),
    [],
  );
  const methodsRef = useRef(null);

  const handleStartUpload = async () => {
    setUploading(true);
    await methodsRef.current?.startUpload?.();
    setUploading(false);
  };

  const goToProcessingPage = () => {
    const redirectUrl = isAU() ? '/processing-pending-poi' : '/processing';
    updateStore({
      activeStep: redirectUrl,
    });
    history.push(redirectUrl);
  };

  const goToProcessingPendingPoiPage = () => {
    updateStore({
      activeStep: '/processing-pending-poi',
    });
    history.push('/processing-pending-poi');
  };

  const goToReplayIncomePage = useCallback(() => {
    updateStore({
      activeStep: '/confirm-income',
    });
    history.push('/confirm-income');
  }, [history, updateStore]);

  const { submit: submitApplication, loading } = useSubmitSavedApplication({
    storeState,
    onSuccess: showDMSView ? goToProcessingPage : goToProcessingPendingPoiPage,
    onError: handleErrorCallback,
  });
  const { updatePOI } = useUpdatePOIType({
    storeState,
    onSuccess: showDMSView ? goToProcessingPage : goToProcessingPendingPoiPage,
    onError: handleErrorCallback,
  });

  const defaultDocumentUploadUrl = byCountry({
    AU: getEnv('REACT_APP_DOCUMENT_UPLOAD_URL_AU'),
    NZ: getEnv('REACT_APP_DOCUMENT_UPLOAD_URL'),
  });

  const uploadUrl = getUploadUrl({ firstName, lastName, applicationRef, defaultDocumentUploadUrl });

  const onDocumentTypeChange = () => {
    trackEventSender({
      category: 'application',
      action: 'application-navigation',
      location: 'upload-documents',
      label: 'what-documents-do-i-need',
    });
  };

  const networkRequest = useCallback(
    poiMethod => {
      if (isAU()) {
        return updatePOI(poiMethod);
      }
      return submitApplication({ incomeVerificationType: poiMethod });
    },
    [updatePOI, submitApplication],
  );

  const onSubmit = ({ errors }) => {
    const updateStoreAndRedirectToProcessing = () => {
      trackEventSender({
        category: 'application',
        action: 'application-navigation',
        location: 'upload-documents',
        label: 'Upload documents now',
      });
      networkRequest(storeState.incomeVerificationType);
    };
    submitHandler({ submit: updateStoreAndRedirectToProcessing, errors });
  };

  const gridColProps = byCountry({
    AU: { sm: '0-8', md: '0-8', lg: '2-9', xl: '2-9' },
    NZ: { sm: '1-6', md: '1-6', lg: '3-8', xl: '3-8' },
  });

  if (storeState.applicationErrors) {
    const retry = () => {
      updateStore({
        applicationErrors: null,
      });

      if (isFeatureOn('incomeReplay')) {
        goToReplayIncomePage();
      } else {
        networkRequest(storeState.incomeVerificationType);
      }
    };

    return (
      <LayoutPage>
        <Errors retry={retry} retrying={loading} />
      </LayoutPage>
    );
  }

  const UPLOAD_TITLES = {
    PAYSLIPS: 'Upload your payslips',
    DOCUMENTS: 'Upload your documents',
  };

  const handleDocUploadEvent = evt => {
    switch (evt.type) {
      case 'ERROR':
        handleErrorTracking(evt.error?.message ?? evt.error);
        break;
      case 'COMPLETE':
        networkRequest(storeState.incomeVerificationType);
        break;
      case 'INIT':
        methodsRef.current = {
          startUpload: evt.data.startUpload,
        };
        break;
      default:
        break;
    }
  };

  const getHeaderOverride = () => {
    const isEnabled = (isAU() && auDmsUpload) || (isNZ() && nzDmsUpload);
    if (!isEnabled) return undefined;
    return showUploadPayslips ? UPLOAD_TITLES.PAYSLIPS : showUploadOtherDocuments ? UPLOAD_TITLES.DOCUMENTS : undefined;
  };

  const { subHeader } = VerifyIncomeContent[config.countryCode];
  const AccordionInfo = () => {
    return (
      <Text>
        <strong>
          Please provide any of the below documents. For a quick response, please upload a legible copy of your
          payslips(s). All documents must contain your full legal name.
        </strong>
      </Text>
    );
  };

  return (
    <LayoutPage headerOverride={getHeaderOverride()}>
      <Container margin="0 0 0 0" hasBreakpointWidth>
        <Form
          initialValues={initialValues}
          id="incomeDocumentUpload"
          onSubmit={onSubmit}
          disableFormCache
          showBackButton
          hideFormButtons
          submitButtonLabel="Upload documents now"
        >
          {formData => {
            const isValid =
              Object.keys(formData.state.errors).length === 0 && formData.state.values.incomeEmploymentType;
            return (
              <>
                {showDMSView && showUploadPayslips ? (
                  <UploadPayslips
                    applicationRef={applicationRef}
                    handleDocUploadEvent={handleDocUploadEvent}
                    channel={dmsChannel}
                  />
                ) : showDMSView && showUploadOtherDocuments ? (
                  <UploadOtherDocuments
                    applicationRef={applicationRef}
                    employmentStatus={employmentStatus}
                    channel={dmsChannel}
                    handleDocUploadEvent={handleDocUploadEvent}
                  />
                ) : (
                  // TODO: remove this section once we have a DMS is released
                  <LayoutContent rowsGap="0">
                    <Container padding="0 0 6 0">
                      <Text>{subHeader}</Text>
                      <Text />
                    </Container>
                    <Container padding="0" margin="0">
                      <Grid preset="page" rowsGap={6}>
                        <Grid.Item colSpan="all">
                          <Text textStyle="heading5">Document upload</Text>
                        </Grid.Item>
                        <Grid.Item colSpan="all">
                          <YellowMessage>
                            <Message testId="yellow-message" severity="warning-or-significant">
                              <Text>
                                <strong>
                                  To complete your application, please upload your proof of income documentation based
                                  on your employment type.
                                </strong>
                              </Text>
                              <Text>
                                For a quick response, please upload a clear legible copy of your payslip(s). For all
                                other documents, our team will review your application and be in touch within 2 business
                                days.
                              </Text>
                            </Message>
                          </YellowMessage>
                        </Grid.Item>
                        <Grid.Item colSpan="all">
                          <Select
                            name="incomeEmploymentType"
                            label="What documents do I need?"
                            options={incomeEmploymentStatusOptionsAU}
                            testId="incomeEmploymentType"
                            placeholder="Please select your employment type"
                            validate={validate}
                            onChange={onDocumentTypeChange}
                          />
                          <Accordion>
                            {formData.state.values.incomeEmploymentType === PERMANENT_FULL_PART_TIME_FIXED_TERM && (
                              <Accordion.Item initiallyOpen>
                                <Accordion.Item.Content>
                                  <AccordionInfo />
                                  <br />
                                  <Text>
                                    <strong>Payslips(s)</strong>
                                  </Text>
                                  <StyledUL>
                                    <li>PDF, screenshot or photo of a full computer-generated payslip</li>
                                    <li>Shows your employer’s name or ACN/ABN</li>
                                    <li>Most recent payment is dated within the last 31 days</li>
                                    <li>
                                      Includes <strong>minimum 2 months of year to date</strong> income or{' '}
                                      <strong>supply 2 consecutive payslips</strong>
                                    </li>
                                    <li>Shows net/gross income and frequency of pay</li>
                                    <li>
                                      If employed by a family member we may need an additional document; such as a bank
                                      statement or accountant letter
                                    </li>
                                  </StyledUL>
                                  <Text>
                                    <strong>Bank Statement/Online Transaction History</strong>
                                  </Text>
                                  <StyledUL>
                                    <li>Shows your BSB, account number & account name</li>
                                    <li>
                                      <strong>Two months of income payments</strong>, most recent income showing{' '}
                                      <strong>within the last 31 days</strong>
                                    </li>
                                    <li>Employer’s name or ACN/ABN corresponding to the deposits</li>
                                  </StyledUL>
                                </Accordion.Item.Content>
                              </Accordion.Item>
                            )}
                            {formData.state.values.incomeEmploymentType === CASUAL_SEASONAL_TEMPORARY && (
                              <Accordion.Item initiallyOpen>
                                <Accordion.Item.Content>
                                  <AccordionInfo />
                                  <br />
                                  <Text>
                                    <strong>Payslips(s)</strong>
                                  </Text>
                                  <StyledUL>
                                    <li>Is a PDF, screenshot or photo of a full computer-generated payslip</li>
                                    <li>Shows your employer’s name or ACN/ABN</li>
                                    <li>
                                      Most recent payment is dated <strong>within the last 31 days</strong>
                                    </li>
                                    <li>Includes minimum 2 months of year to date income</li>
                                    <li>Shows net/gross income and frequency of pay</li>
                                    <li>
                                      If applying between <strong>1 July and 31 August</strong> supply an additional
                                      payslip/ATO Income Statement from the end of prior financial
                                    </li>
                                  </StyledUL>
                                  <Text>
                                    <strong>Bank Statement/Online Transaction History</strong>
                                  </Text>
                                  <StyledUL>
                                    <li>Shows your BSB, account number & account name</li>
                                    <li>
                                      Two months of income payments, most recent income showing{' '}
                                      <strong>within the last 31 days</strong>
                                    </li>
                                    <li>Employer’s name or ACN/ABN corresponding to the deposits</li>
                                  </StyledUL>
                                  <Text>
                                    <strong>ATO Income Statement / Group Certificate</strong>
                                  </Text>
                                  <StyledUL>
                                    <li>Shows your employer’s name or ACN/ABN</li>
                                    <li>
                                      Is for the most recent financial tax year, if applying in the following financial
                                      year between <strong>1 July and 31 August</strong>, and
                                    </li>
                                    <li>
                                      Supplied with a payslip dated within the <strong>last 31 days</strong>
                                    </li>
                                  </StyledUL>
                                </Accordion.Item.Content>
                              </Accordion.Item>
                            )}
                            {formData.state.values.incomeEmploymentType === GOVERNMENT_BENEFITS && (
                              <Accordion.Item initiallyOpen>
                                <Accordion.Item.Content>
                                  <AccordionInfo />
                                  <br />
                                  <Text>
                                    <strong>Bank Statement / Online Transaction History</strong>
                                  </Text>
                                  <StyledUL>
                                    <li>Shows your BSB, account number & account name</li>
                                    <li>
                                      Two consecutive income payments, most recent income showing{' '}
                                      <strong>within the last 31 days</strong>
                                    </li>
                                    <li>Shows deposit amounts and dates</li>
                                    <li>Shows identifiable income transaction, e.g. Centrelink</li>
                                  </StyledUL>
                                  <Text>
                                    <strong>Centrelink / Services Australia Statement / Letter</strong>
                                  </Text>
                                  <StyledUL>
                                    <li>Shows the Agency name</li>
                                    <li>Benefit type, amount and frequency</li>
                                    <li>
                                      Is dated <strong>within the last 31 days</strong>
                                    </li>
                                  </StyledUL>
                                  <Text>
                                    <strong>Centrelink/Services Australia Online Payment History</strong>
                                  </Text>
                                  <StyledUL>
                                    <li>Shows the Agency name</li>
                                    <li>Benefit type, amount and frequency</li>
                                    <li>
                                      Two consecutive income payments, most recent income showing{' '}
                                      <strong>within the last 31 days</strong>
                                    </li>
                                  </StyledUL>
                                  <Text>
                                    <strong>Veterans Affairs Statement /Letter</strong>
                                  </Text>
                                  <StyledUL>
                                    <li>Shows income amount and frequency</li>
                                    <li>
                                      Is on DVA letterhead, dated <strong>within the last 31 days</strong>
                                    </li>
                                  </StyledUL>
                                </Accordion.Item.Content>
                              </Accordion.Item>
                            )}
                            {formData.state.values.incomeEmploymentType === SELF_EMPLOYED_SUBCONTRACTOR && (
                              <Accordion.Item initiallyOpen>
                                <Accordion.Item.Content>
                                  <AccordionInfo />
                                  <br />
                                  <Text>
                                    <strong>Tax Office Notice of Assessment</strong>
                                  </Text>
                                  <StyledUL>
                                    <li>Shows Australian Taxation Office details</li>
                                    <li>
                                      Is for End of Financial Year date <strong>within the last 18 months</strong>
                                    </li>
                                    <li>Shows the taxable income</li>
                                    <li>
                                      Is accompanied by your bank statement <strong>showing 3 months of income</strong>
                                    </li>
                                  </StyledUL>
                                  <Text>
                                    <strong>Accountant Letter</strong>
                                  </Text>
                                  <StyledUL>
                                    <li>Shows your accountant’s name, address, ABN/ACN and telephone number</li>
                                    <li>
                                      Is dated <strong>within the last 31 days</strong>
                                    </li>
                                    <li>States your income after expenses</li>
                                    <li>
                                      Is accompanied by your bank statement <strong>showing 3 months of income</strong>
                                    </li>
                                  </StyledUL>
                                  <Text>
                                    <strong>Tax Return </strong>
                                  </Text>
                                  <StyledUL>
                                    <li>Is prepared by an accountant with accountant details included</li>
                                    <li>
                                      End of Financial Year date <strong>within the last 18 months,</strong>
                                    </li>
                                    <li>Includes all pages of the “accountant prepared individual Tax Return”</li>
                                    <li>
                                      Is accompanied by your bank statement <strong>showing 3 months of income</strong>
                                    </li>
                                  </StyledUL>
                                  <Text>
                                    <strong>Profit and Loss Statement</strong>
                                  </Text>
                                  <StyledUL>
                                    <li>Is prepared by an accountant with accountant details included</li>
                                    <li>
                                      Shows income period <strong>within the last 18 months</strong>
                                    </li>
                                    <li>Specifies you as a sole trader</li>
                                    <li>
                                      Is accompanied by your bank statement <strong>showing 3 months of income</strong>
                                    </li>
                                  </StyledUL>
                                </Accordion.Item.Content>
                              </Accordion.Item>
                            )}
                            {formData.state.values.incomeEmploymentType === RENTAL_INCOME && (
                              <Accordion.Item initiallyOpen>
                                <Accordion.Item.Content>
                                  <AccordionInfo />
                                  <br />
                                  <Text>
                                    <strong>Bank Statement / Online Transaction History</strong>
                                  </Text>
                                  <StyledUL>
                                    <li>Shows your BSB, account number & account name</li>
                                    <li>
                                      Two months of income payments, most recent income showing{' '}
                                      <strong>within the last 31 days</strong>
                                    </li>
                                    <li>Tenant or rental agency’s name corresponding to the deposits</li>
                                  </StyledUL>
                                  <Text>
                                    <strong>Real Estate Rental Statements or Receipts</strong>
                                  </Text>
                                  <StyledUL>
                                    <li>Shows the last 2 months’ rental income</li>
                                    <li>
                                      Most recent rental statement or receipt dated{' '}
                                      <strong>within the last 31 days</strong>
                                    </li>
                                    <li>Shows the Real Estate Agent’s name, ACN /ABN, address and telephone number</li>
                                  </StyledUL>
                                  <Text>
                                    <strong>Current Rental Agreement </strong>
                                  </Text>
                                  <StyledUL>
                                    <li>Is current, formally executed</li>
                                    <li>Shows tenant’s name</li>
                                    <li>Term of rental agreement</li>
                                    <li>Gross rental income</li>
                                    <li>Contracting parties’ signatures</li>
                                    <li>Accompanied with another rental income document if over 4 weeks old</li>
                                  </StyledUL>
                                </Accordion.Item.Content>
                              </Accordion.Item>
                            )}
                            {formData.state.values.incomeEmploymentType === SELF_FUNDED_RETIREES && (
                              <Accordion.Item initiallyOpen>
                                <Accordion.Item.Content>
                                  <AccordionInfo />
                                  <br />
                                  <Text>
                                    <strong>Bank Statement / Online Transaction History</strong>
                                  </Text>
                                  <StyledUL>
                                    <li>Shows your BSB, account number & account name</li>
                                    <li>
                                      Three months of income payments, most recent income showing{' '}
                                      <strong>within the last 31 days</strong>
                                    </li>
                                    <li>
                                      If latest payment is not within 31 days provide with another income document
                                    </li>
                                  </StyledUL>
                                  <Text>
                                    <strong>Tax Office Notice of Assessment</strong>
                                  </Text>
                                  <StyledUL>
                                    <li>Shows Australian Taxation Office details</li>
                                    <li>
                                      Is for End of Financial Year date <strong>within the last 18 months</strong>
                                    </li>
                                    <li>Shows the taxable income</li>
                                    <li>
                                      Is accompanied by your bank statement <strong>showing 3 months of income</strong>
                                    </li>
                                  </StyledUL>
                                  <Text>
                                    <strong>Accountant Letter</strong>
                                  </Text>
                                  <StyledUL>
                                    <li>Shows your accountant’s name, address, ABN/ACN and telephone number</li>
                                    <li>
                                      Is dated <strong>within the last 31 days</strong>
                                    </li>
                                    <li>States your income after expenses</li>
                                    <li>
                                      Is accompanied by your bank statement <strong>showing 3 months of income</strong>
                                    </li>
                                  </StyledUL>
                                  <Text>
                                    <strong>Tax Return</strong>
                                  </Text>
                                  <StyledUL>
                                    <li>Is prepared by an accountant with accountant details included</li>
                                    <li>
                                      End of Financial Year date <strong>within the last 18 months,</strong>
                                    </li>
                                    <li>Includes all pages of the “accountant prepared individual Tax Return”</li>
                                    <li>
                                      Is accompanied by your bank statement <strong>showing 3 months of income</strong>
                                    </li>
                                  </StyledUL>
                                  <Text>
                                    <strong>Superannuation Statement</strong>
                                  </Text>
                                  <StyledUL>
                                    <li>Shows superannuation provider name, ABN/ACN and address</li>
                                    <li>
                                      Issued <strong>within the last 45 days</strong>
                                    </li>
                                    <li>Shows net or gross income</li>
                                    <li>Shows benefit type, amount and frequency</li>
                                    <li>Current balance of the fund</li>
                                  </StyledUL>
                                  <Text>
                                    <strong>Letter from Superannuation Fund</strong>
                                  </Text>
                                  <StyledUL>
                                    <li>Shows fund name and ABN/ACN</li>
                                    <li>
                                      Issued <strong>within the last 31 days</strong>
                                    </li>
                                    <li>Shows net or gross income</li>
                                    <li>Pay frequency</li>
                                  </StyledUL>
                                </Accordion.Item.Content>
                              </Accordion.Item>
                            )}
                            {formData.state.values.incomeEmploymentType === WORKERS_COMPENSATION && (
                              <Accordion.Item initiallyOpen>
                                <Accordion.Item.Content>
                                  <AccordionInfo />
                                  <br />
                                  <Text>
                                    <strong>Letter from Insurer</strong>
                                  </Text>
                                  <StyledUL>
                                    <li>Shows name of insurer or relevant body administering payments</li>
                                    <li>Start date of payments</li>
                                    <li>The end date (if applicable), and the amount and frequency of payments</li>
                                    <li>
                                      Is dated <strong>within the last 31 days</strong>
                                    </li>
                                  </StyledUL>
                                </Accordion.Item.Content>
                              </Accordion.Item>
                            )}
                            {formData.state.values.incomeEmploymentType === CHILD_SUPPORT && (
                              <Accordion.Item initiallyOpen>
                                <Accordion.Item.Content>
                                  <AccordionInfo />
                                  <br />
                                  <Text>
                                    <strong>Legal Documentation or Child Support Agency Documentation</strong>
                                  </Text>
                                  <StyledUL>
                                    <li>
                                      Is dated <strong>within the last 12 months</strong>
                                    </li>
                                    <li>
                                      Accompanied by your bank statement showing 2 months of income payments, most
                                      recent income <strong>within the last 31 days</strong>
                                    </li>
                                  </StyledUL>
                                </Accordion.Item.Content>
                              </Accordion.Item>
                            )}
                          </Accordion>
                        </Grid.Item>
                      </Grid>
                      <Container margin="10 0 0 0">
                        <Text>Acceptable file types PNG, JPG, TIFF, BMP, PDF.</Text>
                        <Text>Maximum size is 10MB each file.</Text>
                      </Container>
                    </Container>
                  </LayoutContent>
                )}

                <Container margin="0 0 13 0">
                  <Container padding="5 0">
                    <Grid preset="page">
                      <Grid.Item
                        colSpan="all"
                        colSpan-sm={gridColProps.sm}
                        colSpan-md={gridColProps.md}
                        colSpan-lg={gridColProps.lg}
                        colSpan-xl={gridColProps.xl}
                      >
                        <Grid rowsGap="4" colsGap="2">
                          <Grid.Item colSpan="all" colSpan-sm="0-4" rowSpan-sm="0">
                            <Grid>
                              {showDMSView ? (
                                <SubmitButton onClick={handleStartUpload} loading={uploading} />
                              ) : (
                                <UploadButton
                                  isValid={isValid}
                                  uploadUrl={uploadUrl}
                                  formData={formData}
                                  loading={loading}
                                />
                              )}
                            </Grid>
                          </Grid.Item>
                          <Grid.Item colSpan="all" colSpan-sm="5-7" alignItems="center">
                            <Grid>
                              {isAU() ? (
                                <SaveAndCompleteLaterButton />
                              ) : (
                                <Button testId="backButton" type="button" variant="secondary" onClick={onClickBack}>
                                  Back
                                </Button>
                              )}
                            </Grid>
                          </Grid.Item>
                        </Grid>
                      </Grid.Item>
                    </Grid>
                  </Container>
                </Container>
              </>
            );
          }}
        </Form>
      </Container>
    </LayoutPage>
  );
}

function validate(value, utils) {
  if (utils.isEmpty(value)) {
    return 'Please select your employment type';
  }
  return null;
}

const UploadButton = ({ isValid, uploadUrl, formData, loading }) => {
  if (isValid) {
    return (
      <Link
        appearance="primary-button"
        variant="blue-button"
        loading={loading}
        onClick={formData.submitForm}
        newTab
        href={uploadUrl}
      >
        <span style={{ cursor: 'pointer' }}>Upload documents now</span>
      </Link>
    );
  }
  return (
    <Button type="submit" loading={loading}>
      Upload documents now
    </Button>
  );
};

const SubmitButton = ({ onClick, loading }) => {
  return (
    <Button width="100%" type="button" disabled={loading} onClick={onClick}>
      Submit
    </Button>
  );
};
